import React, {HTMLProps} from 'react'

export interface GradientContainerProps extends HTMLProps<HTMLDivElement> {
  rtl?: boolean
  containerProps?: HTMLProps<HTMLDivElement>
  dark?: boolean
}

export default function GradientContainer({rtl, className = '', children, dark = false, containerProps, ...props}: GradientContainerProps) {
  return (
    <div
      className={`
        relative transition-colors 
        ${dark ? 'bg-gray-section' : 'bg-white'}
        ${rtl ? 'bg-gradient-to-bl' : 'bg-gradient-to-tr'}
        ${className}
      `}
      {...props}
    >
      <div
        {...containerProps}
        className={`
          container mx-auto py-20 lg:py-28
          ${containerProps?.className || ''}
        `}
      >
        {children}
      </div>
    </div>
  )
}
