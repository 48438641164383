import React, {HTMLProps} from 'react'

export interface ScrollLinkProps extends HTMLProps<HTMLAnchorElement> {
  enabled?: boolean
}

export default function ScrollLink({children, href = '/', onClick, enabled = true, ...props}: ScrollLinkProps) {
  const isClient = typeof window !== 'undefined'

  return (
    <a
      {...props}
      href={enabled ? `/${href?.replace(/^\//, '#')}` : href}
      onClick={event => {
        event.preventDefault()

        onClick && onClick(event)

        const target = href?.replace(/^\//, '')

        if (enabled) {
          if (target === '') {

            if (isClient) {
              window?.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
              setTimeout(() => {
                history.pushState('', document.title, location.pathname + location.search)
              }, 500)
            }
          } else if (isClient) {
            document.querySelector(`#${target}`)?.scrollIntoView({
              behavior: 'smooth',
            })
            setTimeout(() => {
              history.pushState('', document.title, location.pathname + location.search + `#${target}`)
            }, 500)
          }
        } else {
          // Behave like normal link
          if (isClient) {
            location.href = target === '' ? '/' : `/#${target}`
          }
        }
      }}
    >
      {children}
    </a>
  )
}
