import React, {HTMLProps} from 'react'

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  // outlined?: boolean
}

export default function Button({children, ...props}: ButtonProps) {
  return (
    // @ts-ignore
    <button
      {...props}
      className={`
        border-2 border-ci bg-ci-darker text-white inline-flex items-center space-x-2 rounded-full px-6 py-1 hover:shadow-blur duration-1000 transition-colors disabled:opacity-50
        ${props.className || ''}
      `}
    >
      {children}
    </button>
  )
}
