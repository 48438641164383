import React, {useRef} from 'react'
import {createPortal} from 'react-dom'
import Button from './Button'
import DarkModeToggle from 'react-dark-mode-toggle'
import {Link} from 'gatsby'
import ScrollLink from './ScrollLink'
import close from './../images/close.svg'

export interface DrawerProps {
  open: boolean
  onClose: () => void
  darkMode: boolean
  mainPage?: boolean
  onChangeDarkMode: () => void
}

export default function Drawer({open, onClose, darkMode, onChangeDarkMode, mainPage = false}: DrawerProps) {
  const backdrop = useRef<HTMLDivElement|null>()

  if (typeof window === 'undefined') {
    return null
  }

  return createPortal(
    <div
      ref={ref => backdrop.current = ref}
      onClick={(event) => {
        if (open && event.target === backdrop.current) {
          onClose()
        }
      }}
      className={`fixed z-[101] top-0 right-0 bottom-0 left-0 transition-all duration-500 ${
        open 
          ? 'bg-white/50' 
          : 'pointer-events-none'
      }`}
    >
      <div
        className={`absolute top-0 bottom-0 right-0 overflow-y-scroll w-[80vw] max-w-[400px] bg-white text-ci-gray transition-all duration-300 ${
          open
            ? `translate-x-0 shadow-lg`
            : `translate-x-[80vw] pointer-events-none`
        }`}
      >
        <div className="flex flex-col space-y-16">
          <div className="flex items-center p-4">
            <div className="h-[40px]">
              {/*{typeof window !== 'undefined' && (*/}
              {/*  <DarkModeToggle*/}
              {/*    onChange={onChangeDarkMode}*/}
              {/*    checked={darkMode}*/}
              {/*    size={80}*/}
              {/*  />*/}
              {/*)}*/}
            </div>
            <div className="ml-auto">
              <button
                onClick={onClose}
                aria-label="Menü schließen"
              >
                <img src={close} width="37px" height="38px" alt="" aria-label="Menü schließen" />
              </button>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center space-y-4 px-4">
            <a
              href="https://www.doctolib.de/plastische-und-asthetische-chirurgie/fulda/eva-maria-pless"
              target="_blank"
            >
              <Button aria-label="Termin vereinbaren">
                <span>
                  Termin vereinbaren
                </span>
              </Button>
            </a>
            <p className="text-sm">
              <ScrollLink
                href="/kontakt"
                enabled={mainPage}
                onClick={onClose}
              >
                Oder schreiben Sie mir eine Nachricht
              </ScrollLink>
            </p>
          </div>
          <ul className="flex flex-col w-full">
            <li>
              <ScrollLink
                href="/"
                enabled={mainPage}
                onClick={onClose}
                className="flex border-b border-ci p-4 w-full hover:text-ci-darker hover:bg-ci/20"
              >
                Startseite
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                href="/meine-philosophie"
                enabled={mainPage}
                onClick={onClose}
                className="flex border-b border-ci p-4 w-full hover:text-ci-darker hover:bg-ci/20"
              >
                Philosophie
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                href="/qualifikation-und-ausbildung"
                enabled={mainPage}
                onClick={onClose}
                className="flex border-b border-ci p-4 w-full hover:text-ci-darker hover:bg-ci/20"
              >
                Qualifikation
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                href="/kontakt"
                enabled={mainPage}
                onClick={onClose}
                className="flex border-b border-ci p-4 w-full hover:text-ci-darker hover:bg-ci/20"
              >
                Kontakt aufnehmen
              </ScrollLink>
            </li>
            <li>
              <Link
                to="/impressum"
                className="flex border-b border-ci p-4 w-full hover:text-ci-darker hover:bg-ci/20"
              >
                Impressum
              </Link>
            </li>
            <li>
              <Link
                to="/datenschutz"
                className="flex p-4 w-full hover:text-ci-darker hover:bg-ci/20"
              >
                Datenschutz
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>,
    document?.body
  )
}
