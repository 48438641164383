import React, {Fragment, HTMLProps} from 'react'

const colors = [
    '#F98665',
    '#FAB4A0',
    '#FB7A54',
    '#F69D83',
    '#F45022',
]

export interface HorizontalBorderProps extends HTMLProps<HTMLDivElement> {
    barCount?: number
}

const shuffle = ([...arr]) => {
    let m = arr.length
    while (m) {
        const i = Math.floor(Math.random() * m--);
        [arr[m], arr[i]] = [arr[i], arr[m]]
    }
    return arr
}

export default function HorizontalBorder(
    {
        barCount = 5,
        className = '',
        ...other
    }: HorizontalBorderProps
) {
    // Shuffle colors
    const _colors = shuffle(colors)

    const bars = shuffle([
        <div className="h-full w-[20%]" style={{background: _colors[0]}}></div>,
        <div className="h-full w-[27%]" style={{background: _colors[1]}}></div>,
        <div className="h-full w-[15%]" style={{background: _colors[2]}}></div>,
        <div className="h-full w-[13%]" style={{background: _colors[3]}}></div>,
        <div className="h-full w-[25%]" style={{background: _colors[4]}}></div>,
    ])

    return (
        <div className={`flex h-[4px] ${className}`} {...other}>
            {bars.map((bar, i) => (
                <Fragment
                    key={i}
                >
                    {bar}
                </Fragment>
            ))}
        </div>
    )
}
