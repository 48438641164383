import React, {HTMLProps, useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {Link} from 'gatsby'
import DarkModeToggle from 'react-dark-mode-toggle'
import ScrollLink from './ScrollLink'
import logo from '../images/logo.png'
import logoFull from './../images/logo.svg'
import logoGoogle from './../images/logo_google.png'
import googleSD from './../images/google_sd.jpg'
import Button from './Button'
import Drawer from './Drawer'
import HorizontalBorder from './HorizontalBorder'

export interface LayoutProps extends HTMLProps<HTMLDivElement> {
  mainPage?: boolean
  menuHidden?: boolean
}

const getScrollPosition = () => {
  return typeof window !== 'undefined' ? {
    x: window.pageXOffset || document.documentElement.scrollLeft,
    y: window.pageYOffset || document.documentElement.scrollTop
  } : {x: 0, y: 0}
}

export default function Layout({children, mainPage = false, menuHidden = false, ...props}: LayoutProps) {
  const isClient = typeof window !== 'undefined'
  const [smallHeader, setSmallHeader] = useState<boolean>(true)
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  // Initialize dark/bright mode
  const defaultDarkScheme = false
  const initWithDarkMode = isClient && localStorage?.getItem('dark')
      ? localStorage?.getItem('dark') === 'true'
      : (
          isClient && window.matchMedia('(prefers-color-scheme: dark)').matches
              ? true
              : defaultDarkScheme
      )

  const [darkMode, setDarkMode] = useState<boolean>(initWithDarkMode)

  // Toggle dark mode and set preferences in local storage
  const toggleDarkMode = (): void => {
    setDarkMode(prevState => {
      const newMode = !prevState

      isClient && localStorage?.setItem('dark', newMode ? 'true' : 'false')

      return newMode
    })
  }

  // Set header size based on scroll position
  const setHeaderSize = (event?: Event): void => {
    event?.preventDefault()
    event?.stopPropagation()

    const scrollY = getScrollPosition()?.y
    const scrollThreshold = 100

    if (scrollY > scrollThreshold) {
      setSmallHeader(true)
    } else if (scrollY === 0) {
      setSmallHeader(false)
    }
  }

  // useEffect(() => {
  //   // Change dark mode if system preferences change and dark mode is not set in local storage
  //   isClient && window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
  //     if ( ! localStorage?.getItem('dark')) {
  //       setDarkMode(event.matches)
  //     }
  //   })
  //
  //   // Set header size
  //   setHeaderSize()
  //
  //   isClient && window.addEventListener('scroll', setHeaderSize)
  //
  //   return () => {
  //     isClient && window.removeEventListener('scroll', setHeaderSize)
  //   }
  // }, [])

  return (
      <div {...props}>
        <Helmet
            titleTemplate="%s - Fachärztin für Plastische und Ästhetische Chirurgie"
            htmlAttributes={{
              lang: 'de',
              class: `
            relative
            ${darkMode ? 'dark' : 'bright'}
            ${drawerOpen ? 'overflow-hidden' : 'overflow-x-hidden'}
          `,
            }}
            bodyAttributes={{
              class: `
            relative bg-white dark:bg-dark-bg text-ci-gray text-base transition-colors
          `
            }}
        >
          <title>Dr. med. Eva Maria Pless</title>
          <meta name="description" content="Dr. med. Eva Maria Pless - Fachärztin für Plastische und Ästhetische Chirurgie."/>
          {/*<script type="application/ld+json">*/}
          {/*  {`*/}
          {/*    {*/}
          {/*      "@context": "https://schema.org",*/}
          {/*      "@type": "LocalBusiness",*/}
          {/*      "name": "Dr. med. Eva Maria Pless",*/}
          {/*      "address": {*/}
          {/*        "@type": "PostalAddress",*/}
          {/*        "streetAddress": "Kaiserstraße 9",*/}
          {/*        "addressLocality": "Hünfeld",*/}
          {/*        "addressRegion": "HE",*/}
          {/*        "postalCode": "36088",*/}
          {/*        "addressCountry": "DE"*/}
          {/*      },*/}
          {/*      "geo": {*/}
          {/*        "@type": "GeoCoordinates",*/}
          {/*        "latitude": 50.672720,*/}
          {/*        "longitude": 9.764960*/}
          {/*      },*/}
          {/*      "logo": "https://marcos-fahrschule.de${logoGoogle}",*/}
          {/*      "image": "https://marcos-fahrschule.de${googleSD}",*/}
          {/*      "url": "https://marcos-fahrschule.de",*/}
          {/*      "telephone": "+496652747777",*/}
          {/*      "openingHoursSpecification": [*/}
          {/*        {*/}
          {/*          "@type": "OpeningHoursSpecification",*/}
          {/*          "dayOfWeek": [*/}
          {/*            "Montag",*/}
          {/*            "Dienstag",*/}
          {/*            "Mittwoch",*/}
          {/*            "Donnerstag",*/}
          {/*            "Freitag"*/}
          {/*          ],*/}
          {/*          "opens": "14:00",*/}
          {/*          "closes": "18:30"*/}
          {/*        }*/}
          {/*      ]*/}
          {/*    }*/}
          {/*  `}*/}
          {/*</script>*/}
        </Helmet>

        <HorizontalBorder
            className="sticky top-0 z-[100]"
        />

        {!menuHidden && (
            <header className="sticky top-[4px] z-[100] bg-white text-header-text text-base transition-all">
              <div className={`container mx-auto flex items-center py-4 ${smallHeader ? 'md:py-2 lg:py-2' : 'lg:py-2'}`}>
                <div className="flex items-center space-x-2 md:space-x-8 lg:space-x-16">
                  <ScrollLink
                      enabled={mainPage}
                      className="inline"
                      href={`/`}
                      onClick={event => {
                        event.preventDefault()

                        window?.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      }}
                  >
                    <img
                        src={logo}
                        alt="Logo Dr. med. Eva Maria Pless"
                        width="1px"
                        height="1px"
                        className={`${smallHeader ? '!w-[57px]' : 'w-[57px] md:w-[73px] lg:w-[90px] xl:w-[120px]'} h-auto transition-all`}
                    />
                  </ScrollLink>
                  <div className="flex items-center space-x-4 sm:space-x-8">
                    <nav role="navigation">
                      <ul className="hidden lg:flex items-center space-x-4 xl:space-x-8">
                        <li>
                          <ScrollLink
                              enabled={mainPage}
                              href="/"
                              className="px-2 py-1 text-ci-darker hover:text-ci-dark rounded-full transition-colors"
                          >
                            Startseite
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                              enabled={mainPage}
                              href="/meine-philosophie"
                              className="px-2 py-1 text-ci-darker hover:text-ci-dark rounded-full transition-colors"
                          >
                            Philosophie
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                              enabled={mainPage}
                              href="/qualifikation-und-ausbildung"
                              className="px-2 py-1 text-ci-darker hover:text-ci-dark rounded-full transition-colors"
                          >
                            Qualifikation
                          </ScrollLink>
                        </li>
                        <li>
                          <ScrollLink
                              enabled={mainPage}
                              href="/kontakt"
                              className="px-2 py-1 text-ci-darker hover:text-ci-dark rounded-full transition-colors"
                          >
                            Kontakt aufnehmen
                          </ScrollLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="flex justify-center ml-auto">
                  <button
                      className="lg:hidden"
                      onClick={() => {
                        setDrawerOpen(open => !open)
                      }}
                      aria-label="Menü öffnen"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="26" viewBox="0 0 47 26">
                      <g fill="#F87E5B" fillRule="evenodd">
                        <rect width="47" height="4" rx="2"/>
                        <rect width="47" height="4" y="11" rx="2"/>
                        <rect width="47" height="4" y="22" rx="2"/>
                      </g>
                    </svg>
                  </button>
                  <Drawer
                      open={drawerOpen}
                      onClose={() => setDrawerOpen(false)}
                      darkMode={darkMode}
                      mainPage={mainPage}
                      onChangeDarkMode={toggleDarkMode}
                  />

                  <a
                      href="https://www.doctolib.de/plastische-und-asthetische-chirurgie/fulda/eva-maria-pless"
                      target="_blank"
                  >
                    <Button
                        className="hidden lg:inline-flex"
                        aria-label="Termin vereinbaren"
                    >
                      <span>
                        Termin vereinbaren
                      </span>
                    </Button>
                  </a>
                </div>
              </div>
            </header>
        )}

        <main>
          {children}
        </main>

        <HorizontalBorder/>

        {!menuHidden && (
            <footer className="bg-white text-ci-gray">
              <div className="container mx-auto py-16 px-8 flex flex-col md:flex-row space-y-16 md:space-y-0 md:space-x-16 xl:space-x-16">
                <div className="flex items-center gap-4 sm:gap-8 md:gap-16 mx-auto">
                  <img src={logo} alt=""/>
                  <nav role="navigation">
                    <ul className="flex flex-col lg:flex-row lg:items-center gap-4">
                      <li>
                        <ScrollLink
                            enabled={mainPage}
                            href="/"
                            className="px-2 py-1 text-ci-darker hover:text-ci-dark rounded-full transition-colors"
                        >
                          Startseite
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                            enabled={mainPage}
                            href="/meine-philosophie"
                            className="px-2 py-1 text-ci-darker hover:text-ci-dark rounded-full transition-colors"
                        >
                          Philosophie
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                            enabled={mainPage}
                            href="/qualifikation-und-ausbildung"
                            className="px-2 py-1 text-ci-darker hover:text-ci-dark rounded-full transition-colors"
                        >
                          Qualifikation
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                            enabled={mainPage}
                            href="/kontakt"
                            className="px-2 py-1 text-ci-darker hover:text-ci-dark rounded-full transition-colors"
                        >
                          Kontakt aufnehmen
                        </ScrollLink>
                      </li>
                      <li>
                        <a
                            href="/datenschutz"
                            className="px-2 py-1 text-ci-darker hover:text-ci-dark rounded-full transition-colors"
                        >
                          Datenschutz
                        </a>
                      </li>
                      <li>
                        <a
                            href="/impressum"
                            className="px-2 py-1 text-ci-darker hover:text-ci-dark rounded-full transition-colors"
                        >
                          Impressum
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </footer>
        )}
      </div>
  )
}
